.react-datepicker__input-container {
  margin-bottom: 5px;
  input {
    cursor: pointer;
    border: none;
    font-size: 10px;
    width: 100%;
    text-align: center;

    &:hover,
    &:focus {
      color: #f4701b;
    }
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #f4701b;
  color: #fff;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: rgba(244, 112, 27, 0.5);
  color: #fff;
}
//F6F7FB
.react-datepicker__day--selected:not([aria-disabled="true"]):hover,
.react-datepicker__day--in-selecting-range:not([aria-disabled="true"]):hover,
.react-datepicker__day--in-range:not([aria-disabled="true"]):hover,
.react-datepicker__month-text--selected:not([aria-disabled="true"]):hover,
.react-datepicker__month-text--in-selecting-range:not([aria-disabled="true"]):hover,
.react-datepicker__month-text--in-range:not([aria-disabled="true"]):hover,
.react-datepicker__quarter-text--selected:not([aria-disabled="true"]):hover,
.react-datepicker__quarter-text--in-selecting-range:not([aria-disabled="true"]):hover,
.react-datepicker__quarter-text--in-range:not([aria-disabled="true"]):hover,
.react-datepicker__year-text--selected:not([aria-disabled="true"]):hover,
.react-datepicker__year-text--in-selecting-range:not([aria-disabled="true"]):hover,
.react-datepicker__year-text--in-range:not([aria-disabled="true"]):hover {
  background-color: #f4701b;
  color: #fff;
}

.react-datepicker__header {
  background-color: #f6f7fb;
  border-bottom: 1px solid #f4701b;
}

.react-datepicker__month-container {
  @media (max-width: 768px) {
    width: 100%;
  }
}

.react-datepicker__input-container.error {
  input {
    color: rgba(255, 0, 0);
  }
}
