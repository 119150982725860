.menu-button {
  width: 250px;
  padding: 10px 15px;
  cursor: pointer;
}

.menu {
  position: absolute;
  top: 45px;
  width: 250px;
  left: 10px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
}

.menu-item {
  padding: 15px 20px;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.submenu {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: none;
  width: 100%;
  border-radius: 8px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.submenu::-webkit-scrollbar {
  width: 8px;
}

.submenu::-webkit-scrollbar-thumb {
  background-color: #f4701b;
  border-radius: 3px;
}

.submenu::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.menu-item:hover .submenu {
  display: block;
}

.submenu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  font-family: var(--cygreRegular), sans-serif;
}

.submenu-item input {
  margin-right: 15px;
}

@media screen and (max-width: 600px) {
  .menu-item {
    position: relative;
  }
  .submenu {
    background-color: #ffff;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1001;
  }
}
