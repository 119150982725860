 .dropdown {
          position: relative;
          display: inline-block;
        }
        .dropdown-toggle {
          background-color: #f0f0f0;
          padding: 8px 16px;
          cursor: pointer;
        }
        .dropdown-menu {
          position: absolute;
          top: 100%;
          left: -145px;
          background-color: white;
          border: 1px solid #ddd;
          list-style: none;
          margin: 0;
          padding: 0;
          width: 160px;
          border-radius: 8px;
        }
        .dropdown-item {
          padding: 8px 16px;
          cursor: pointer;
        }
        .dropdown-item:hover {
          background-color: #f0f0f0;
        }
        .dropdown-item {
          .selected {
            background-color: #eea578;
          }
        }
